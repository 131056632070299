<template>
  <b-card class="custom-shadow">
    <b-tabs  nav-class="mb-2 ml-2" class="appointment-block">
      <b-tab :title="$t('General Information')" active class="ml-2">
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Activity') }}</b> {{customerAppointment.AppointmenActivity.activity_name }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Appointment Date') }}:</b> {{ formattedDayNumber(customerAppointment.AppointmentTimeTable.start_date) }}
          {{ formattedMonthName(customerAppointment.AppointmentTimeTable.start_date) }} {{ formattedYearNumber(customerAppointment.AppointmentTimeTable.start_date) }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Start Time') }}:</b> {{ startTime(customerAppointment.AppointmentTimeTable.start_date) }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('End Time') }}</b> {{ endTime(customerAppointment.AppointmentTimeTable.end_date) }}
        </b-card-text>
      </b-tab> 
      <b-tab :title="$t('Facility')" class="ml-2">
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Facility') }}:</b> {{ customerAppointment.AppointmentCompanyBranch.company_branch_name }}
        </b-card-text>
         <b-card-text class="text-left pl-1" v-if="customerAppointment.AppointmentRoom != null">
          <b>{{ $t('Room') }}:</b> {{customerAppointment.AppointmentRoom.room_name !== null ? customerAppointment.AppointmentRoom.room_name : 'Belirtilmemiş' }}
        </b-card-text>
      </b-tab>
      <b-tab :title="$t('Trainer')" class="ml-2">
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Trainer Name') }}:</b> {{ customerAppointment.AppointmentTrainer.first_name }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Trainer Surname') }}:</b> {{ customerAppointment.AppointmentTrainer.last_name }}
        </b-card-text>
      </b-tab>
    </b-tabs>
    <div>
        <hr style="margin-left:-15px; margin-right:-15px;">
      <div class="float-right" v-if="customerAppointment.is_canceled_button == true">
            <b-button @click="appointmentCancel(customerAppointment.AppointmentTimeTable.time_table_detail_id)" variant="outline-danger ml-1">{{ $t('Cancellation') }}</b-button>
      </div> 
        <div v-if="customerAppointment.AppointmentStudioDoor != null">
          <div v-if="customerAppointment.AppointmentStudioDoor.is_door == true">
            <div class="float-right" v-if="customerAppointment.is_door_button == true">
              <b-button @click="doorOpen(customerAppointment.AppointmentTimeTable.company_branch_id,customerAppointment.AppointmentTimeTable.time_table_id, customerAppointment.AppointmentTimeTable.time_table_detail_id)" variant="outline-success">{{ $t('Open the door') }}
                <feather-icon  class="ml-1" size="16" icon="UnlockIcon"/>
              </b-button>
            </div>
            <div v-else class="float-right">
              <b-button   :id="customerAppointment.AppointmentTimeTable.time_table_detail_id" @click="reloadPage"  variant="outline-warning"> {{ $t("Refresh") }}
                <feather-icon  class="ml-1" size="16" icon="RefreshCwIcon"/>
              </b-button>
            </div>
          </div>                  
      </div>
    </div>
  </b-card>
</template>

<script>
import {
      BAlert,
      BButton,
      BCard,
      BCardText,
      BCardTitle,
      BCol,
      BFormSelect,
      BImg,
      BPopover,
      VBPopover,
      BCardBody,
      BMediaAside,
      BRow,
      BTab,
      BTabs
    } from 'bootstrap-vue'
export default {
  name: 'CustomerAppointmentCard',
  props: {
    customerAppointment: {
      type: Object,
      required: true
    },
        },
        diretives: {
            VBPopover: VBPopover
        },
  data() {
    return {
      coutDownTimer:0,
      pgvalue :0,
      ml: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BCardText,
    BCardTitle,
    BButton,
    BFormSelect,
    BPopover,
    VBPopover,
    BCardBody,
    BAlert,
    BImg,
    BMediaAside
  },
  methods: {

    reloadPage() {
      window.location.reload();
    },


    isExpired(end_date) {
      return this.moment > new Date(end_date)
    },
    isCancelVerify(start_date) {
      return new Date(start_date).getTime() - new Date().getTime() > 3600000
    },
    appointmentCancel(time_table_detail_id) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t('Are You Sure You Want To Cancel Your Appointment?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$database.AppointmentService.change_appointment_activity(time_table_detail_id)
                  .then(res => {
                    if (res.IsSuccess === true || res.IsSuccess === 1) {
                        this.$functions.Messages.success(res.Result);
                      //randevu iptali başarılı olduktan sonra emit yayarız ve üst bileşende randevu listesini yenileriz
                        this.$emit("randevuIptal")
                        window.location.reload();
                    }
                    else
                    {
                      this.$functions.Messages.error(res.Result)
                    }
                  })
            }
          })
    },
    doorOpen(company_branch_id,time_table_id, time_table_detail_id) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text:"Kapıyı açmak istediğinizden eminmisiniz", //this.$t('Are You Sure You Want To Cancel Your Appointment?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$database.StudioService.StudioDoorOpen(company_branch_id, time_table_id, time_table_detail_id)
                  .then(res => {
                    if (res.IsSuccess === true || res.IsSuccess === 1) {
                        this.$functions.Messages.success(res.Result);
                      //randevu iptali başarılı olduktan sonra emit yayarız ve üst bileşende randevu listesini yenileriz
                        this.$emit("randevuIptal")
                    }
                    else
                    {
                      this.$functions.Messages.error(res.Result)
                    }
                  })
            }
          })
    },
    startTime(val) {
      let hour = val.split('T')[1]
      return hour.split(':')[0] + ':' + hour.split(':')[1]
    },
    endTime(val) {
      let date = new Date(val)
      let incrementSecond = date.getTime() + 1000
      let newDate = new Date(incrementSecond)
      let hourMinuteSecond = newDate.toString()
          .split(' ')[4]
      let formattedHMS = hourMinuteSecond.split(':')[0] + ':' + hourMinuteSecond.split(':')[1]
      return formattedHMS
    },
    formattedDayNumber(val) {
      return val.split('T')[0].split('-')[2]
    },
    formattedMonthName(val) {
      return this.$t(this.ml[val.split('T')[0].split('-')[1] - 1])
    },
    formattedYearNumber(val) {
      return val.split('T')[0].split('-')[0]
    },
  },
  watch:{

  },
  computed:{
  },
  created(){
    
  },
mounted : function(){   
},
}
</script>