<template>
  <section id="card-navigation">
    <b-alert variant="light" show>
      <h4 v-if="$i18n.locale === 'tr'" class="alert-heading bg-primary bg-lighten-1 text-white rounded">
        {{ headerText }}         <b>{{ CustomerAppointments.length }}</b> {{$t('Pieces')}}
      </h4>
    </b-alert>
    <b-row class="mb-2 rounded">
        <b-col sm="6">
            <b-form-group>
                <v-select v-model="selectedAppointment"
                          :options="appointmentOptions"
                          value="value"
                          label="text"
                          :reduce="(item) => item.value"
                          class="select-config">
                </v-select>
            </b-form-group>
        </b-col>
    
    </b-row>
    <b-row>
        <b-col  md="6" sm="12" v-for="(customerAppointment,index) in CustomerAppointments" :key="index"> 
            <CustomerAppointmentCard v-if="customerAppointment.is_active_card== true"  :customerAppointment="customerAppointment" @randevuIptal="listeyiYenile()"/>
            <CustomerAppointmentDeactiveCard v-if="customerAppointment.is_active_card == false"  :customerAppointment="customerAppointment" @randevuIptal="listeyiYenile()"/>
        </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BPopover,
  BCardText,
  BCardTitle,
  BCol,
  BFormSelect,
  BFormGroup,
  BImg,
  BMediaAside,
  BRow,
  BTab,
  BTabs,
  VBPopover} from 'bootstrap-vue'

    import vSelect from "vue-select";
    import Ripple from 'vue-ripple-directive'
    import AppointmentCard from '@/components/AppointmentCard/AppointmentCard.vue'
    import CustomerAppointmentCard from '@/components/AppointmentCard/CustomerAppointmentCard.vue'
    import CustomerAppointmentDeactiveCard from '@/components/AppointmentCard/CustomerAppointmentDeactiveCard.vue'
    export default {

  data(){
      return{
        selectedGrid: 6,
          selectedAppointment: {
              value: 1,
              text: this.$t('Upcoming Appointments')
          } ,
        appointmentOptions: [
          // {value:0, text: this.$t('All Appointments')},
          {value:1, text: this.$t('Upcoming Appointments')},
          {value:2, text: this.$t('Expired')},
          {value:3, text: this.$t('Canceled')}
        ],
         myAppointments: [],
        // myBaseAppointments: [],
        headerText:'',
        CustomerAppointments:[]
      }
  },
  components: {
    AppointmentCard,
    CustomerAppointmentCard,
    CustomerAppointmentDeactiveCard,
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    Ripple,
    BCardText,
    BCardTitle,
    BCardBody,
    BPopover,
    BFormGroup,
    VBPopover,
    BButton,
    BFormSelect,
    BAlert,
    BImg,
    BMediaAside,
    vSelect,
  },
  computed: {
    moment(){
      return new Date()
    }
  },
  methods: {
    // time table details tablosundan bana ait randevuları getir
      getMyAppointments(company_branch_id,person_id,appointment_type) 
      {
        this.$database.AppointmentService.get_customer_appointments(company_branch_id,person_id,appointment_type)
        .then((res) => {
          if (res.IsSuccess === true || res.IsSuccess === 1) {         
            res.Result.map((el) => {
              this.CustomerAppointments.push(this.$models.CustomerAppointmentModel(
                el.CustomerAppointmentCompanyBranchCard,
                el.CustomerAppointmentTimeTableCard,
                el.CustomerAppointmentRoomCard,
                el.CustomerAppointmentTrainerCard,
                el.CustomerAppointmenActivityCard,
                el.CustomerAppointmentStudioDoorCard,
                el.IsCanceledButton,
                el.IsDoorButton,
                el.IsActiveCard
              ))
          }); 
          }
        });
      //     this.myAppointments=[];
      //     this.myBaseAppointments = [];
      // this.$database.AppointmentService.get_my_appointments(
      //     this.$store.getters['auth/userInfo'].company_branch_id,
      //     this.$store.getters['auth/userInfo'].person_id
      // )
      //   .then((res) => {
      //     if (res.is_success === true || res.is_success === 1) {
      //       this.myBaseAppointments = res.result;
      //       this.myAppointments = this.myBaseAppointments;
      //     }
      //   })
    },
    listeyiYenile()
    {
       // this.getMyAppointments()
    },
    getSelectText(val)
    {
        const filterdata=this.appointmentOptions.filter(x=> x.value === val)
        if(filterdata.length > 0)
        {
          this.headerText= filterdata[0].text
        }
    },
  },
  watch: {
    selectedAppointment(val)
    {
        this.getSelectText(val);
          this.CustomerAppointments=[];
          this.getMyAppointments(
          this.$store.getters['auth/userInfo'].company_branch_id,
          this.$store.getters['auth/userInfo'].person_id,
          val);
    }
  },


  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  created(){
      this.getMyAppointments(
          this.$store.getters['auth/userInfo'].company_branch_id,
          this.$store.getters['auth/userInfo'].person_id,
          this.selectedAppointment.value);
          this.headerText=this.selectedAppointment.text;
  },
  mounted()
  {
    
  },

}
</script>

<!-- stillendirme -->
<style src="@/styles/css/myAppointments.css"></style>
