<template>
  <b-card 
  class="custom-shadow">
     <b-tabs  nav-class="mb-2 ml-2" class="appointment-block">
      <b-tab :title="$t('General Information')" active class="ml-2">
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Activity') }} : </b> {{customerAppointment.AppointmenActivity.activity_name }}
          <!-- {{ this.customerAppointment.AppointmentTimeTable.is_active }} -->
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Appointment Date') }} :</b> {{ formattedDayNumber(customerAppointment.AppointmentTimeTable.start_date) }}
          {{ formattedMonthName(customerAppointment.AppointmentTimeTable.start_date) }} {{ formattedYearNumber(customerAppointment.AppointmentTimeTable.start_date) }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Start Time') }} :</b> {{ startTime(customerAppointment.AppointmentTimeTable.start_date) }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('End Time') }} :</b> {{ endTime(customerAppointment.AppointmentTimeTable.end_date) }}
        </b-card-text>
      </b-tab> 
      <b-tab :title="$t('Place')" class="ml-2">
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Facility') }} :</b> {{ customerAppointment.AppointmentCompanyBranch.company_branch_name }}
        </b-card-text>
        <b-card-text class="text-left pl-1" v-if="customerAppointment.AppointmentRoom != null">
          <b>{{ $t('Room') }} :</b> {{customerAppointment.AppointmentRoom.room_name !== null ? customerAppointment.AppointmentRoom.room_name : 'Belirtilmemiş' }}
        </b-card-text>
      </b-tab>
      <b-tab :title="$t('Trainer')" class="ml-2">
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Trainer Name') }} :</b> {{ customerAppointment.AppointmentTrainer.first_name }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Trainer Surname') }} :</b> {{ customerAppointment.AppointmentTrainer.last_name }}
        </b-card-text>
      </b-tab>
    </b-tabs>
     <div  class="card-padding" style="padding-top: 0px !important; margin-bottom:0px !important">
      <hr style="margin-left:-15px; margin-right:-15px;">
      <div  v-if="customerAppointment.AppointmentTimeTable.is_active == false">
        <p class="text-primary appointment-canceled">{{ $t('Appointment Canceled') }}</p>
      </div>
    </div> 
  </b-card>
</template>

<script>
import {
      BAlert,
      BButton,
      BCard,
      BCardText,
      BCardTitle,
      BCol,
      BFormSelect,
      BImg,
      BPopover,
      VBPopover,
      BCardBody,
      BMediaAside,
      BRow,
      BTab,
      BTabs
    } from 'bootstrap-vue'
export default {
  name: 'CustomerAppointmentCard',
  props: {
    customerAppointment: {
      type: Object,
      required: true
    },
        },
        diretives: {
            VBPopover: VBPopover
        },
  data() {
    return {
      coutDownTimer:0,
      pgvalue :0,
      ml: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BCardText,
    BCardTitle,
    BButton,
    BFormSelect,
    BPopover,
    VBPopover,
    BCardBody,
    BAlert,
    BImg,
    BMediaAside
  },
  methods: {

    reloadPage() {
      window.location.reload();
    },


    isExpired(end_date) {
      return this.moment > new Date(end_date)
    },
    isCancelVerify(start_date) {
      return new Date(start_date).getTime() - new Date().getTime() > 3600000
    },
  
    startTime(val) {
      let hour = val.split('T')[1]
      return hour.split(':')[0] + ':' + hour.split(':')[1]
    },
    endTime(val) {
      let date = new Date(val)
      let incrementSecond = date.getTime() + 1000
      let newDate = new Date(incrementSecond)
      let hourMinuteSecond = newDate.toString()
          .split(' ')[4]
      let formattedHMS = hourMinuteSecond.split(':')[0] + ':' + hourMinuteSecond.split(':')[1]
      return formattedHMS
    },
    formattedDayNumber(val) {
      return val.split('T')[0].split('-')[2]
    },
    formattedMonthName(val) {
      return this.$t(this.ml[val.split('T')[0].split('-')[1] - 1])
    },
    formattedYearNumber(val) {
      return val.split('T')[0].split('-')[0]
    },
  },
  watch:{

  },
  computed:{
  },
  created(){

  },
mounted() {   

},
}
</script>

<style src="@/styles/css/myAppointments.css"></style>