<template>
  <b-card 
  class="custom-shadow"
          :class="isExpired(appointment.end_date) 
          || appointment.is_active == false ? ($store.getters['appConfig/skin']) === 'dark' ? 'bg-expired-dark' : 'bg-expired' : ''">
    <b-tabs  nav-class="mb-2 ml-2" class="appointment-block">
      <b-tab :title="$t('General Information')" active class="ml-2">
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Activity') }}</b> {{ appointment.activity_name }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Appointment Date') }}:</b> {{ formattedDayNumber(appointment.start_date) }}
          {{ formattedMonthName(appointment.start_date) }} {{ formattedYearNumber(appointment.start_date) }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Start Time') }}:</b> {{ startTime(appointment.start_date) }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('End Time') }}</b> {{ endTime(appointment.end_date) }}
        </b-card-text>
      </b-tab>
      <b-tab :title="$t('Place')" class="ml-2">
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Facility') }}:</b> {{ appointment.company_branch_name }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Room') }}:</b> {{ appointment.room_name !== null ? appointment.room_name : 'Belirtilmemiş' }}
        </b-card-text>
      </b-tab>
      <b-tab :title="$t('Trainer')" class="ml-2">
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Trainer Name') }}:</b> {{ appointment.person_name }}
        </b-card-text>
        <b-card-text class="text-left pl-1">
          <b>{{ $t('Trainer Surname') }}:</b> {{ appointment.last_name }}
        </b-card-text>
      </b-tab>
    </b-tabs>
    <template #footer class="" >
      <div class="float-right" v-if="appointment.is_active == true && !isExpired(appointment.end_date) && isCancelVerify(appointment.start_date)">
          <b-button @click="appointmentCancel(appointment.time_table_detail_id)" variant="danger ml-1">{{ $t('Cancellation') }}</b-button>
      </div>
       <!-- randevu iptal edilmişse -->
      <div v-if="appointment.is_active == false">
        <p class="text-primary appointment-canceled">{{ $t('Appointment Canceled') }}</p>
      </div>
      <!-- randevu süresi geçti -->
      <div v-if="isExpired(appointment.end_date)">
        <p class="text-primary appointment-canceled">{{ $t('Appointment Has Expired') }}</p>
      </div>
      <div class="float-right">
        <b-button @click="doorOpen(appointment.time_table_id, appointment.time_table_detail_id)" variant="success">Kapı aç</b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
import {
      BAlert,
      BButton,
      BCard,
      BCardText,
      BCardTitle,
      BCol,
      BFormSelect,
      BImg,
      BPopover,
      VBPopover,
      BCardBody,
      BMediaAside,
      BRow,
      BTab,
      BTabs
    } from 'bootstrap-vue'
export default {
  name: 'AppointmentCard',
  props: {
    appointment: {
      type: Object,
      required: true
    },
        },
        diretives: {
            VBPopover: VBPopover
        },
  data() {
    return {
      ml: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BCardText,
    BCardTitle,
    BButton,
    BFormSelect,
    BPopover,
    VBPopover,
    BCardBody,
    BAlert,
    BImg,
    BMediaAside
  },
  methods: {
    isExpired(end_date) {
      return this.moment > new Date(end_date)
    },
    isCancelVerify(start_date) {
      return new Date(start_date).getTime() - new Date().getTime() > 3600000
    },
    appointmentCancel(time_table_detail_id) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t('Are You Sure You Want To Cancel Your Appointment?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$database.AppointmentService.change_appointment_activity(time_table_detail_id)
                  .then(res => {
                    if (res.IsSuccess === true || res.IsSuccess === 1) {
                        this.$functions.Messages.success(res.Result);
                      //randevu iptali başarılı olduktan sonra emit yayarız ve üst bileşende randevu listesini yenileriz
                        this.$emit("randevuIptal")
                    }
                    else
                    {
                      this.$functions.Messages.error(res.Result)
                    }
                  })
            }
          })
    },
    doorOpen(time_table_id, time_table_detail_id) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text:"Kapıyı açmak istediğinizden eminmisiniz", //this.$t('Are You Sure You Want To Cancel Your Appointment?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$database.StudioService.StudioDoorOpen(time_table_id, time_table_detail_id)
                  .then(res => {
                    if (res.IsSuccess === true || res.IsSuccess === 1) {
                        this.$functions.Messages.success(res.Result);
                      //randevu iptali başarılı olduktan sonra emit yayarız ve üst bileşende randevu listesini yenileriz
                        this.$emit("randevuIptal")
                    }
                    else
                    {
                      this.$functions.Messages.error(res.Result)
                    }
                  })
            }
          })
    },
    startTime(val) {
      let hour = val.split('T')[1]
      return hour.split(':')[0] + ':' + hour.split(':')[1]
    },
    endTime(val) {
      let date = new Date(val)
      let incrementSecond = date.getTime() + 1000
      let newDate = new Date(incrementSecond)
      let hourMinuteSecond = newDate.toString()
          .split(' ')[4]
      let formattedHMS = hourMinuteSecond.split(':')[0] + ':' + hourMinuteSecond.split(':')[1]
      return formattedHMS
    },
    formattedDayNumber(val) {
      return val.split('T')[0].split('-')[2]
    },
    formattedMonthName(val) {
      return this.$t(this.ml[val.split('T')[0].split('-')[1] - 1])
    },
    formattedYearNumber(val) {
      return val.split('T')[0].split('-')[0]
    },
  }
}
</script>